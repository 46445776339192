import {
  defaultStringFilter,
  type Filter,
} from '@/components/table-filters/constants';
import { BrfCompanyConfig } from '@pigello/pigello-matrix';
import baseFilters, { baseTagFilter } from '../filters';
import { Icons } from '@/components/icons';

const brfCompanyFilters: Filter[] = [
  {
    ...defaultStringFilter,
    title: BrfCompanyConfig.fields.customId.verboseName,
    externalFieldName: BrfCompanyConfig.fields.customId.externalFieldName,
    icon: Icons.id,
  },

  ...baseTagFilter,
  ...baseFilters,
];

export default brfCompanyFilters;
