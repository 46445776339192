import {
  OPERATORS,
  RELATION_FILTER,
  defaultNumberFilter,
  defaultRelationFilter,
  defaultSelectFilter,
  defaultStringFilter,
  type Filter,
} from '@/components/table-filters/constants';
import { BrfApartmentConfig, TenantConfig } from '@pigello/pigello-matrix';
import baseFilters, {
  baseAddressFilters,
  baseBuildingFilter,
  baseGlobalRealEstatesFilter,
  baseTagFilter,
} from '../filters';
import { Icons } from '@/components/icons';

const brfApartmentFilters: Filter[] = [
  ...baseBuildingFilter,
  ...baseGlobalRealEstatesFilter,
  {
    title: 'Innehavare',
    externalFieldName: 'tenants',
    relationModelName: TenantConfig.modelName,
    relationDefaultFilters: {
      show_in_brf_context: { noop: true },
    },
    kind: RELATION_FILTER,
    operators: [...OPERATORS.NOOP],
    defaultOperator: OPERATORS.NOOP[0],
  },
  {
    ...defaultSelectFilter,
    title: BrfApartmentConfig.fields.category.verboseName,
    externalFieldName: BrfApartmentConfig.fields.category.externalFieldName,
    choices: BrfApartmentConfig.fields.category.choices,
    icon: Icons.category,
  },
  {
    ...defaultStringFilter,
    title: BrfApartmentConfig.fields.apartmentId.verboseName,
    externalFieldName: BrfApartmentConfig.fields.apartmentId.externalFieldName,
    icon: Icons.id,
  },
  {
    ...defaultStringFilter,
    title: BrfApartmentConfig.fields.popularName.verboseName,
    externalFieldName: BrfApartmentConfig.fields.popularName.externalFieldName,
  },
  {
    ...defaultStringFilter,
    title: BrfApartmentConfig.fields.customId.verboseName,
    externalFieldName: BrfApartmentConfig.fields.customId.externalFieldName,
    icon: Icons.id,
  },
  {
    ...defaultNumberFilter,
    title: BrfApartmentConfig.fields.usableArea.verboseName,
    externalFieldName: BrfApartmentConfig.fields.usableArea.externalFieldName,
  },
  {
    ...defaultNumberFilter,
    title: BrfApartmentConfig.fields.biArea.verboseName,
    externalFieldName: BrfApartmentConfig.fields.biArea.externalFieldName,
  },
  {
    ...defaultNumberFilter,
    title: BrfApartmentConfig.fields.temperedArea.verboseName,
    externalFieldName: BrfApartmentConfig.fields.temperedArea.externalFieldName,
  },

  {
    ...defaultRelationFilter,
    title: BrfApartmentConfig.fields.tenant.verboseName,
    externalFieldName: BrfApartmentConfig.fields.tenant.externalFieldName,
    relationModelName: TenantConfig.modelName,
    icon: Icons.tennant,
  },
  {
    ...defaultRelationFilter,
    title: BrfApartmentConfig.fields.biTenants.verboseName,
    externalFieldName: BrfApartmentConfig.fields.biTenants.externalFieldName,
    relationModelName: TenantConfig.modelName,
    icon: Icons.tennant,
  },
  ...baseAddressFilters,
  ...baseTagFilter,
  ...baseFilters,
];

export default brfApartmentFilters;
