import {
  defaultStringFilter,
  type Filter,
} from '@/components/table-filters/constants';
import { ExternalClientConfig } from '@pigello/pigello-matrix';
import baseFilters, { baseTagFilter } from '../filters';
import { Icons } from '@/components/icons';

const externalClientFilters: Filter[] = [
  {
    ...defaultStringFilter,
    title: ExternalClientConfig.fields.name.verboseName,
    externalFieldName: ExternalClientConfig.fields.name.externalFieldName,
  },
  {
    ...defaultStringFilter,
    title: ExternalClientConfig.fields.orgNo.verboseName,
    externalFieldName: ExternalClientConfig.fields.orgNo.externalFieldName,
    icon: Icons.number,
  },
  {
    ...defaultStringFilter,
    title: ExternalClientConfig.fields.invoiceEmail.verboseName,
    externalFieldName:
      ExternalClientConfig.fields.invoiceEmail.externalFieldName,
    icon: Icons.email,
  },
  {
    ...defaultStringFilter,
    title: ExternalClientConfig.fields.customId.verboseName,
    externalFieldName: ExternalClientConfig.fields.customId.externalFieldName,
    icon: Icons.id,
  },

  {
    ...defaultStringFilter,
    title: `${ExternalClientConfig.fields.visitingAddress.verboseName} - Gatunamn`,
    externalFieldName: `${ExternalClientConfig.fields.visitingAddress.externalFieldName}__street`,
    icon: Icons.address,
  },
  {
    ...defaultStringFilter,
    title: `${ExternalClientConfig.fields.visitingAddress.verboseName} - Stad`,
    externalFieldName: `${ExternalClientConfig.fields.visitingAddress.externalFieldName}__city`,
    icon: Icons.address,
  },
  {
    ...defaultStringFilter,
    title: `${ExternalClientConfig.fields.visitingAddress.verboseName} - Gatunummer`,
    externalFieldName: `${ExternalClientConfig.fields.visitingAddress.externalFieldName}__street_number`,
    icon: Icons.address,
  },
  {
    ...defaultStringFilter,
    title: `${ExternalClientConfig.fields.visitingAddress.verboseName} - Land`,
    externalFieldName: `${ExternalClientConfig.fields.visitingAddress.externalFieldName}__country`,
    icon: Icons.address,
  },
  {
    ...defaultStringFilter,
    title: `${ExternalClientConfig.fields.invoiceAddress.verboseName} - Gatunamn`,
    externalFieldName: `${ExternalClientConfig.fields.invoiceAddress.externalFieldName}__street`,
    icon: Icons.address,
  },
  {
    ...defaultStringFilter,
    title: `${ExternalClientConfig.fields.invoiceAddress.verboseName} - Stad`,
    externalFieldName: `${ExternalClientConfig.fields.invoiceAddress.externalFieldName}__city`,
    icon: Icons.address,
  },
  {
    ...defaultStringFilter,
    title: `${ExternalClientConfig.fields.invoiceAddress.verboseName} - Gatunummer`,
    externalFieldName: `${ExternalClientConfig.fields.invoiceAddress.externalFieldName}__street_number`,
    icon: Icons.address,
  },
  {
    ...defaultStringFilter,
    title: `${ExternalClientConfig.fields.invoiceAddress.verboseName} - Land`,
    externalFieldName: `${ExternalClientConfig.fields.invoiceAddress.externalFieldName}__country`,
    icon: Icons.address,
  },

  ...baseTagFilter,
  ...baseFilters,
];

export default externalClientFilters;
