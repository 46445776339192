'use client';

import { Badge } from '@/components/ui/badge';
import { cn } from '@/lib/cn';
import { toReadableDateTime } from '@/lib/dates';
import { getRelationFieldNames } from '@/requests/instanceMapper';
import {
  PaymentMethodConfig,
  type PaymentMethod,
} from '@pigello/pigello-matrix';
import Dot from './dot';
import type { RelationFieldProps } from './types';

export const PaymentMethodItem: RelationFieldProps<PaymentMethod> = {
  nested: getRelationFieldNames(PaymentMethodConfig),
  filters: [],
  renderItem: (item, isRelationField) => ({
    title: item?.title,
    badge: (
      <Badge variant={item.isPrimaryPaymentMethod ? 'green' : 'gray'}>
        {item.isPrimaryPaymentMethod ? 'Primär' : 'Sekundär'}
      </Badge>
    ),
    descriptionItems: (
      <div
        className={cn(
          'flex w-full items-center',
          isRelationField && 'justify-between'
        )}
      >
        <span className='font-medium'>
          Skapad {toReadableDateTime(item.createdAt)}
        </span>
        {!isRelationField && <Dot />}
        {item.bank}
      </div>
    ),
  }),
};
