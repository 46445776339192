import {
  defaultRelationFilter,
  type Filter,
} from '@/components/table-filters/constants';
import {
  ApartmentConfig,
  BlockContractConfig,
  IndustrialPremisesConfig,
  OutdoorSectionConfig,
  ParkingSpotConfig,
} from '@pigello/pigello-matrix';
import baseFilters, {
  baseGlobalRealEstatesFilter,
  baseTagFilter,
} from '../../filters';
import baseContractFilters from '../components/base-contract-filters';
import { Icons } from '@/components/icons';

const blockContractFilters: Filter[] = [
  {
    ...defaultRelationFilter,
    title: BlockContractConfig.fields.apartments.verboseName,
    relationModelName: ApartmentConfig.modelName,
    externalFieldName: BlockContractConfig.fields.apartments.externalFieldName,
    icon: Icons.apartment,
  },
  {
    ...defaultRelationFilter,
    title: BlockContractConfig.fields.parkingSpots.verboseName,
    relationModelName: ParkingSpotConfig.modelName,
    externalFieldName:
      BlockContractConfig.fields.parkingSpots.externalFieldName,
    icon: Icons.parkingspot,
  },
  {
    ...defaultRelationFilter,
    title: BlockContractConfig.fields.outdoorSections.verboseName,
    relationModelName: OutdoorSectionConfig.modelName,
    externalFieldName:
      BlockContractConfig.fields.outdoorSections.externalFieldName,
    icon: Icons.outdoorsection,
  },
  {
    ...defaultRelationFilter,
    title: BlockContractConfig.fields.industrialPremises.verboseName,
    relationModelName: IndustrialPremisesConfig.modelName,
    externalFieldName:
      BlockContractConfig.fields.industrialPremises.externalFieldName,
    icon: Icons.industrialpremises,
  },
  ...baseGlobalRealEstatesFilter,
  ...baseContractFilters,
  ...baseTagFilter,
  ...baseFilters,
];

export default blockContractFilters;
