import { articleCategoryFilters } from '@/app/settings/modules/invoicing/articles/categories/filters';
import { Badge } from '@/components/ui/badge';
import { cn } from '@/lib/cn';
import { getRelationFieldNames } from '@/requests/instanceMapper';
import {
  ArticleCategoryConfig,
  type ArticleCategory,
} from '@pigello/pigello-matrix';
import Dot from './dot';
import type { RelationFieldProps } from './types';

export const ArticleCategoryItem: RelationFieldProps<ArticleCategory> = {
  nested: getRelationFieldNames(ArticleCategoryConfig),
  filters: articleCategoryFilters,
  renderItem: (item, isRelationField) => ({
    title: item?.name,
    badge: (
      <Badge variant={item?.considerBaseRent ? 'green' : 'gray'}>
        {item?.considerBaseRent ? 'Bashyra' : 'Ej bashyra'}
      </Badge>
    ),
    descriptionItems: (
      <div
        className={cn(
          'flex w-full items-center',
          isRelationField && 'justify-between'
        )}
      >
        <span className='font-medium'>
          {item.archived ? 'Arkiverad' : 'Ej arkiverad'}
        </span>
        {!isRelationField && <Dot />}
        <span>{item?.customId ?? 'Inget objekts-id angivet'}</span>
      </div>
    ),
  }),
};
