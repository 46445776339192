import {
  defaultDateFilter,
  defaultEnumFilter,
  defaultRelationFilter,
  type FilterEnumChoice,
  type Filter,
} from '@/components/table-filters/constants';
import { CompanyConfig, VacancyInvoiceConfig } from '@pigello/pigello-matrix';
import baseFilters, { baseTagFilter } from '../../filters';
import { Icons } from '@/components/icons';

const VACANCY_INVOICE_STATUS_FILTERS: FilterEnumChoice[] = [
  {
    filters: [{ operator: 'noop', value: 'attested' }],
    title: 'Attesterad',
    badgeVariant: 'green',
    overrideExternalFieldName: 'custom_vacancyinvoice_filter',
  },
  {
    filters: [{ operator: 'noop', value: 'not_attested' }],
    title: 'Ej attesterad',
    badgeVariant: 'yellow',
  },
  {
    filters: [{ operator: 'noop', value: 'annulled' }],
    title: 'Makulerad',
    badgeVariant: 'destructive',
  },
];

const vacancyInvoiceFilters: Filter[] = [
  {
    ...defaultEnumFilter,
    title: 'Status',
    externalFieldName: 'custom_vacancyinvoice_filter',
    enumChoices: VACANCY_INVOICE_STATUS_FILTERS,
  },
  {
    ...defaultDateFilter,
    title: VacancyInvoiceConfig.fields.attestedDate.verboseName,
    externalFieldName:
      VacancyInvoiceConfig.fields.attestedDate.externalFieldName,
  },

  {
    ...defaultDateFilter,
    title: VacancyInvoiceConfig.fields.periodStart.verboseName,
    externalFieldName:
      VacancyInvoiceConfig.fields.periodStart.externalFieldName,
  },
  {
    ...defaultDateFilter,
    title: VacancyInvoiceConfig.fields.periodEnd.verboseName,
    externalFieldName: VacancyInvoiceConfig.fields.periodEnd.externalFieldName,
  },
  {
    ...defaultRelationFilter,
    title: VacancyInvoiceConfig.fields.company.verboseName,
    externalFieldName: VacancyInvoiceConfig.fields.company.externalFieldName,
    relationModelName: CompanyConfig.modelName,
    icon: Icons.company,
  },

  ...baseTagFilter,
  ...baseFilters,
];

export default vacancyInvoiceFilters;
