import regionFilters from '@/app/dashboard/structural/regions/filters';
import { getRelationFieldNames } from '@/requests/instanceMapper';
import { RegionConfig, type Region } from '@pigello/pigello-matrix';
import type { RelationFieldProps } from './types';

export const RegionItem: RelationFieldProps<Region> = {
  filters: regionFilters,
  nested: getRelationFieldNames(RegionConfig),
  renderItem: (item) => ({
    title: item?.name,
    descriptionItems: (
      <span className='font-medium'>
        {item.customId ?? 'Inget objekts-id angivet'}
      </span>
    ),
  }),
};
