import { cn } from '@/lib/cn';
import { getRelationFieldNames } from '@/requests/instanceMapper';
import {
  SensorSpecificationConfig,
  type SensorSpecification,
} from '@pigello/pigello-matrix';
import Dot from './dot';
import type { RelationFieldProps } from './types';

export const SensorSpecificationItem: RelationFieldProps<SensorSpecification> =
  {
    nested: getRelationFieldNames(SensorSpecificationConfig),
    filters: [],
    renderItem: (item, isRelationField) => ({
      title: item?.name,
      descriptionItems: (
        <div
          className={cn(
            'flex w-full items-center',
            isRelationField && 'justify-between'
          )}
        >
          <span className='font-medium'>
            {item.unitName ?? 'Ingen enhet angiven'}
          </span>
          {!isRelationField && <Dot />}
          <span>
            {SensorSpecificationConfig.fields.category.choices?.find(
              (c) => c.v === item.category
            )?.d ?? 'Ingen kategori angiven'}
          </span>
        </div>
      ),
    }),
  };
