import { getVacancyNotationStatus } from '@/app/dashboard/vacancy-contracts/apartment/utils';
import parkingSpotVacancyContractFilter from '@/app/dashboard/vacancy-contracts/parking-spot/filters';
import { Badge } from '@/components/ui/badge';
import { cn } from '@/lib/cn';
import { toReadableDate } from '@/lib/dates';
import { getRelationFieldNames } from '@/requests/instanceMapper';
import {
  ParkingSpotVacancyNotationConfig,
  type ParkingSpotVacancyNotation,
} from '@pigello/pigello-matrix';
import type { RelationFieldProps } from './types';

export const ParkingSpotVacancyItem: RelationFieldProps<ParkingSpotVacancyNotation> =
  {
    nested: getRelationFieldNames(ParkingSpotVacancyNotationConfig),
    filters: parkingSpotVacancyContractFilter,
    renderItem: (item, isRelationField) => ({
      title: item?.space?.popularName ?? item.space?.customId ?? 'Saknar ID',
      badge: (() => {
        const { label, variant } = getVacancyNotationStatus(item);
        return <Badge variant={variant}>{label}</Badge>;
      })(),
      descriptionItems: (
        <div
          className={cn(
            isRelationField ? 'grid w-full' : 'flex flex-row items-center'
          )}
        >
          <span>Startdatum {toReadableDate(item.startDate)}</span>
          {item.endDate && (
            <span>Slutdatum {toReadableDate(item.endDate)}</span>
          )}
        </div>
      ),
    }),
  };
