import {
  defaultStringFilter,
  type Filter,
} from '@/components/table-filters/constants';
import { RegionConfig } from '@pigello/pigello-matrix';
import baseFilters, { baseTagFilter } from '../../filters';

const regionFilters: Filter[] = [
  {
    ...defaultStringFilter,
    title: RegionConfig.fields.name.verboseName,
    externalFieldName: RegionConfig.fields.name.externalFieldName,
  },
  {
    ...defaultStringFilter,
    title: RegionConfig.fields.customId.verboseName,
    externalFieldName: RegionConfig.fields.customId.externalFieldName,
  },
  {
    ...defaultStringFilter,
    title: `${RegionConfig.fields.mainAddress.verboseName} - Gatunamn`,
    externalFieldName: `${RegionConfig.fields.mainAddress.externalFieldName}__street`,
  },
  {
    ...defaultStringFilter,
    title: `${RegionConfig.fields.mainAddress.verboseName} - Stad`,
    externalFieldName: `${RegionConfig.fields.mainAddress.externalFieldName}__city`,
  },
  {
    ...defaultStringFilter,
    title: `${RegionConfig.fields.mainAddress.verboseName} - Gatunummer`,
    externalFieldName: `${RegionConfig.fields.mainAddress.externalFieldName}__street_number`,
  },
  {
    ...defaultStringFilter,
    title: `${RegionConfig.fields.mainAddress.verboseName} - Land`,
    externalFieldName: `${RegionConfig.fields.mainAddress.externalFieldName}__country`,
  },
  ...baseTagFilter,
  ...baseFilters,
];

export default regionFilters;
