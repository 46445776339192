import {
  defaultDateFilter,
  defaultNumberFilter,
  defaultRelationFilter,
  defaultSelectFilter,
  defaultStringFilter,
  type Filter,
} from '@/components/table-filters/constants';
import { OutdoorAreaConfig, RealEstateConfig } from '@pigello/pigello-matrix';
import baseFilters, { baseTagFilter } from '../../filters';
import { Icons } from '@/components/icons';

const outdoorAreaFilters: Filter[] = [
  {
    ...defaultStringFilter,
    title: OutdoorAreaConfig.fields.name.verboseName,
    externalFieldName: OutdoorAreaConfig.fields.name.externalFieldName,
  },
  {
    ...defaultStringFilter,
    title: OutdoorAreaConfig.fields.customId.verboseName,
    externalFieldName: OutdoorAreaConfig.fields.customId.externalFieldName,
  },
  {
    ...defaultSelectFilter,
    title: OutdoorAreaConfig.fields.structureOperationType.verboseName,
    externalFieldName:
      OutdoorAreaConfig.fields.structureOperationType.externalFieldName,
    choices: OutdoorAreaConfig.fields.structureOperationType.choices,
  },
  {
    ...defaultSelectFilter,
    title: OutdoorAreaConfig.fields.structureType.verboseName,
    externalFieldName: OutdoorAreaConfig.fields.structureType.externalFieldName,
    choices: OutdoorAreaConfig.fields.structureType.choices,
  },
  {
    ...defaultRelationFilter,
    title: OutdoorAreaConfig.fields.realestate.verboseName,
    externalFieldName: OutdoorAreaConfig.fields.realestate.externalFieldName,
    relationModelName: RealEstateConfig.modelName,
    icon: Icons.realestate,
  },
  {
    ...defaultNumberFilter,
    title: OutdoorAreaConfig.fields.registeredUsableArea.verboseName,
    externalFieldName:
      OutdoorAreaConfig.fields.registeredUsableArea.externalFieldName,
  },
  {
    ...defaultNumberFilter,
    title: OutdoorAreaConfig.fields.registeredGrossArea.verboseName,
    externalFieldName:
      OutdoorAreaConfig.fields.registeredGrossArea.externalFieldName,
  },
  {
    ...defaultNumberFilter,
    title: OutdoorAreaConfig.fields.registeredBuildingArea.verboseName,
    externalFieldName:
      OutdoorAreaConfig.fields.registeredBuildingArea.externalFieldName,
  },
  {
    ...defaultNumberFilter,
    title: OutdoorAreaConfig.fields.registeredBiArea.verboseName,
    externalFieldName:
      OutdoorAreaConfig.fields.registeredBiArea.externalFieldName,
  },
  {
    ...defaultNumberFilter,
    title: OutdoorAreaConfig.fields.calculatedUsableArea.verboseName,
    externalFieldName:
      OutdoorAreaConfig.fields.calculatedUsableArea.externalFieldName,
  },
  {
    ...defaultNumberFilter,
    title: OutdoorAreaConfig.fields.calculatedBiArea.verboseName,
    externalFieldName:
      OutdoorAreaConfig.fields.calculatedBiArea.externalFieldName,
  },
  {
    ...defaultDateFilter,
    title: OutdoorAreaConfig.fields.availabilityStartDate.verboseName,
    externalFieldName:
      OutdoorAreaConfig.fields.availabilityStartDate.externalFieldName,
  },
  {
    ...defaultDateFilter,
    title: OutdoorAreaConfig.fields.availabilityEndDate.verboseName,
    externalFieldName:
      OutdoorAreaConfig.fields.availabilityEndDate.externalFieldName,
  },
  {
    ...defaultDateFilter,
    title: OutdoorAreaConfig.fields.builtDate.verboseName,
    externalFieldName: OutdoorAreaConfig.fields.builtDate.externalFieldName,
  },
  {
    ...defaultDateFilter,
    title: OutdoorAreaConfig.fields.demolitionDate.verboseName,
    externalFieldName:
      OutdoorAreaConfig.fields.demolitionDate.externalFieldName,
  },

  ...baseTagFilter,
  ...baseFilters,
];

export default outdoorAreaFilters;
