import {
  defaultStringFilter,
  type Filter,
} from '@/components/table-filters/constants';
import { AddressConfig } from '@pigello/pigello-matrix';
import baseFilters from '../../filters';

const addressFilters: Filter[] = [
  {
    ...defaultStringFilter,
    title: AddressConfig.fields.street.verboseName,
    externalFieldName: AddressConfig.fields.street.externalFieldName,
  },
  {
    ...defaultStringFilter,
    title: AddressConfig.fields.streetNumber.verboseName,
    externalFieldName: AddressConfig.fields.streetNumber.externalFieldName,
  },
  {
    ...defaultStringFilter,
    title: AddressConfig.fields.postalCode.verboseName,
    externalFieldName: AddressConfig.fields.postalCode.externalFieldName,
  },
  {
    ...defaultStringFilter,
    title: AddressConfig.fields.city.verboseName,
    externalFieldName: AddressConfig.fields.city.externalFieldName,
  },
  {
    ...defaultStringFilter,
    title: AddressConfig.fields.country.verboseName,
    externalFieldName: AddressConfig.fields.country.externalFieldName,
  },
  {
    ...defaultStringFilter,
    title: AddressConfig.fields.customId.verboseName,
    externalFieldName: AddressConfig.fields.customId.externalFieldName,
  },

  ...baseFilters,
];

export default addressFilters;
