import {
  defaultNumberFilter,
  defaultRelationFilter,
  defaultSelectFilter,
  defaultStringFilter,
  type Filter,
} from '@/components/table-filters/constants';
import { ApartmentConfig, TenantConfig } from '@pigello/pigello-matrix';
import baseRevenueIncreaseFilters from '../contracts/components/base-revenue-increase-filters';
import baseFilters, {
  baseAddressFilters,
  baseBuildingFilter,
  baseGlobalRealEstatesFilter,
  baseSpaceStatusFilters,
  baseTagFilter,
} from '../filters';
import { Icons } from '@/components/icons';

const apartmentFilters: Filter[] = [
  ...baseSpaceStatusFilters,
  ...baseBuildingFilter,
  ...baseGlobalRealEstatesFilter,
  {
    ...defaultSelectFilter,
    title: ApartmentConfig.fields.category.verboseName,
    externalFieldName: ApartmentConfig.fields.category.externalFieldName,
    choices: ApartmentConfig.fields.category.choices,
  },
  {
    ...defaultStringFilter,
    title: ApartmentConfig.fields.apartmentId.verboseName,
    externalFieldName: ApartmentConfig.fields.apartmentId.externalFieldName,
    icon: Icons.id,
  },
  {
    ...defaultStringFilter,
    title: ApartmentConfig.fields.popularName.verboseName,
    externalFieldName: ApartmentConfig.fields.popularName.externalFieldName,
  },
  {
    ...defaultStringFilter,
    title: ApartmentConfig.fields.customId.verboseName,
    externalFieldName: ApartmentConfig.fields.customId.externalFieldName,
    icon: Icons.id,
  },
  {
    ...defaultNumberFilter,
    title: ApartmentConfig.fields.usableArea.verboseName,
    externalFieldName: ApartmentConfig.fields.usableArea.externalFieldName,
  },
  {
    ...defaultNumberFilter,
    title: ApartmentConfig.fields.biArea.verboseName,
    externalFieldName: ApartmentConfig.fields.biArea.externalFieldName,
  },
  {
    ...defaultNumberFilter,
    title: ApartmentConfig.fields.temperedArea.verboseName,
    externalFieldName: ApartmentConfig.fields.temperedArea.externalFieldName,
  },

  {
    ...defaultRelationFilter,
    title: ApartmentConfig.fields.tenant.verboseName,
    externalFieldName: ApartmentConfig.fields.tenant.externalFieldName,
    relationModelName: TenantConfig.modelName,
    icon: Icons.tennant,
  },
  {
    ...defaultRelationFilter,
    title: ApartmentConfig.fields.biTenants.verboseName,
    externalFieldName: ApartmentConfig.fields.biTenants.externalFieldName,
    relationModelName: TenantConfig.modelName,
    icon: Icons.tennant,
  },
  ...baseRevenueIncreaseFilters,
  ...baseAddressFilters,
  ...baseTagFilter,
  ...baseFilters,
];

export default apartmentFilters;
