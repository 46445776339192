import {
  defaultBooleanFilter,
  defaultDateFilter,
  defaultRelationFilter,
  defaultStringFilter,
  type Filter,
} from '@/components/table-filters/constants';
import {
  ApartmentConfig,
  ApartmentVacancyNotationConfig,
} from '@pigello/pigello-matrix';
import baseFilters, {
  baseGlobalRealEstatesFilter,
  baseTagFilter,
} from '../../filters';
import { Icons } from '@/components/icons';

const apartmentVacancyContractFilter: Filter[] = [
  {
    ...defaultStringFilter,
    title: 'Avtals-ID',
    externalFieldName: 'custom_id',
    icon: Icons.id,
  },
  {
    ...defaultRelationFilter,
    title: ApartmentVacancyNotationConfig.fields.space.verboseName,
    relationModelName: ApartmentConfig.modelName,
    externalFieldName:
      ApartmentVacancyNotationConfig.fields.space.externalFieldName,
    icon: Icons.apartment,
  },
  {
    ...defaultBooleanFilter,
    title: ApartmentVacancyNotationConfig.fields.automatic.verboseName,
    externalFieldName: 'automatic',
  },
  {
    ...defaultBooleanFilter,
    title: ApartmentVacancyNotationConfig.fields.annulled.verboseName,
    externalFieldName: 'annulled',
  },
  {
    ...defaultDateFilter,
    title: ApartmentVacancyNotationConfig.fields.startDate.verboseName,
    externalFieldName: 'start_date',
  },
  {
    ...defaultDateFilter,
    title: ApartmentVacancyNotationConfig.fields.endDate.verboseName,
    externalFieldName: 'end_date',
  },
  ...baseGlobalRealEstatesFilter,
  ...baseTagFilter,
  ...baseFilters,
];

export default apartmentVacancyContractFilter;
