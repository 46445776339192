'use client';
import brfApartmentFilters from '@/app/dashboard/brf-apartments/filters';
import { cn } from '@/lib/cn';
import { toReadableAddress } from '@/lib/utils';
import { getRelationFieldNames } from '@/requests/instanceMapper';
import {
  ApartmentConfig,
  BrfApartmentConfig,
  type BrfApartment,
} from '@pigello/pigello-matrix';
import Dot from './dot';
import type { RelationFieldProps } from './types';

export const BrfApartmentItem: RelationFieldProps<BrfApartment> = {
  nested: getRelationFieldNames(BrfApartmentConfig),
  filters: brfApartmentFilters,
  renderItem: (item, isRelationField) => ({
    title: item?.apartmentId ?? item.customId,

    descriptionItems: (
      <div
        className={cn(
          isRelationField ? 'grid w-full' : 'flex flex-row items-center'
        )}
      >
        <div className='font-medium'>
          {item.address
            ? toReadableAddress(item.address, true, false)
            : 'Ingen adress angiven'}
        </div>
        {!isRelationField && <Dot />}
        <div
          className={cn(
            'flex w-full items-center',
            isRelationField && 'justify-between'
          )}
        >
          <span>
            {ApartmentConfig.fields.category.choices?.find(
              (c) => c.v === item?.category
            )?.d ?? 'Ingen kategori angiven'}
          </span>
          {!isRelationField && <Dot />}
          <span> {item.customId ?? 'Inget objekts-id angivet'}</span>
        </div>
      </div>
    ),
  }),
};
