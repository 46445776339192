import type { BaseVacancyNotation } from '@pigello/pigello-matrix';
import { DateTime } from 'luxon';

export const getVacancyNotationStatus = (
  notation: BaseVacancyNotation
): {
  label: string;
  variant:
    | 'default'
    | 'gray'
    | 'light-gray'
    | 'green'
    | 'yellow'
    | 'blue'
    | 'purple'
    | 'orange'
    | 'cyan'
    | 'destructive'
    | 'outline';
} => {
  const start = notation.startDate
    ? DateTime.fromISO(notation.startDate)
    : null;
  const end = notation.endDate ? DateTime.fromISO(notation.endDate) : null;
  const now = DateTime.now();
  if (!start) return { label: 'Okänt', variant: 'gray' };

  if (start > now) return { label: 'Kommande', variant: 'blue' };

  if (!end) return { label: 'Aktiv tillsvidare', variant: 'green' };

  if (end) {
    if (end < now) return { label: 'Tidigare', variant: 'yellow' };
    if (end < now.plus({ months: 3 }))
      return { label: 'Utgående', variant: 'orange' };

    if (end > now) return { label: 'Aktiv', variant: 'green' };
  }

  return { label: 'Okänt', variant: 'gray' };
};
