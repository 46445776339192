'use client';

import { getRelationFieldNames } from '@/requests/instanceMapper';
import {
  ContractCancellationReasonConfig,
  type ContractCancellationReason,
} from '@pigello/pigello-matrix';
import type { RelationFieldProps } from './types';

export const CancellationReasonItem: RelationFieldProps<ContractCancellationReason> =
  {
    nested: getRelationFieldNames(ContractCancellationReasonConfig),
    filters: [],
    renderItem: (item) => ({
      title: item.title,
      descriptionItems: (
        <div>
          <div className='font-medium'>{item.description}</div>
        </div>
      ),
    }),
  };
