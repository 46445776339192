'use client';

import { InvoiceStatus } from '@/app/dashboard/invoicing/invoices/components/invoice-status';
import invoiceFilters from '@/app/dashboard/invoicing/invoices/filters';
import { cn } from '@/lib/cn';
import { toReadableDateTime } from '@/lib/dates';
import { toMoneyString } from '@/lib/utils';
import { getRelationFieldNames } from '@/requests/instanceMapper';
import { InvoiceConfig, type Invoice } from '@pigello/pigello-matrix';
import Dot from './dot';
import type { RelationFieldProps } from './types';

export const InvoiceItem: RelationFieldProps<Invoice> = {
  filters: invoiceFilters,
  nested: getRelationFieldNames(InvoiceConfig),
  renderItem: (item, isRelationField) => ({
    title: item.invoiceNumber
      ? `${
          (item.invoiceValuesMeta?.total_value ?? 0) > -1
            ? item.rentalInvoice
              ? 'Avi'
              : 'Faktura'
            : item.rentalInvoice
              ? 'Kreditavi'
              : 'Kreditfaktura'
        } nr. ${item.invoiceNumber}`
      : (item.invoiceValuesMeta?.total_value ?? 0) > -1
        ? item.rentalInvoice
          ? 'Avi'
          : 'Faktura'
        : item.rentalInvoice
          ? 'Kreditavi'
          : 'Kreditfaktura',
    badge: <InvoiceStatus invoice={item} />,
    descriptionItems: (
      <div className='grid w-full'>
        <div
          className={cn(
            'flex w-full items-center',
            isRelationField && 'justify-between'
          )}
        >
          <div className='flex w-full items-center justify-between font-medium'>
            <span>
              {item.attestedDate
                ? `Attesterad ${item.attestedDate}`
                : 'Ej attesterad'}
            </span>
            {!isRelationField && <Dot />}
            Skapad {toReadableDateTime(item.createdAt)}
          </div>
        </div>
        <div
          className={cn(
            'flex w-full items-center',
            isRelationField && 'justify-between'
          )}
        >
          <span> Fakturadatum {item.invoiceDate}</span>
          {!isRelationField && <Dot />}
          <span> Förfallodatum {item.dueDate}</span>
        </div>
        <div
          className={cn(
            'flex w-full items-center',
            isRelationField && 'justify-between'
          )}
        >
          <span>
            Originalbelopp{' '}
            {toMoneyString(item.invoiceValuesMeta?.total_value ?? 0)}
          </span>
        </div>
      </div>
    ),
  }),
};
