import apartmentVacancyContractFilter from '@/app/dashboard/vacancy-contracts/apartment/filters';
import { getVacancyNotationStatus } from '@/app/dashboard/vacancy-contracts/apartment/utils';
import { Badge } from '@/components/ui/badge';
import { cn } from '@/lib/cn';
import { toReadableDate } from '@/lib/dates';
import { getRelationFieldNames } from '@/requests/instanceMapper';
import {
  ApartmentVacancyNotationConfig,
  type ApartmentVacancyNotation,
} from '@pigello/pigello-matrix';
import type { RelationFieldProps } from './types';

export const ApartmentVacancyItem: RelationFieldProps<ApartmentVacancyNotation> =
  {
    nested: getRelationFieldNames(ApartmentVacancyNotationConfig),
    filters: apartmentVacancyContractFilter,
    renderItem: (item, isRelationField) => ({
      title: item?.space?.popularName ?? item.space?.customId ?? 'Saknar ID',
      badge: (() => {
        const { label, variant } = getVacancyNotationStatus(item);
        return <Badge variant={variant}>{label}</Badge>;
      })(),
      descriptionItems: (
        <div
          className={cn(
            isRelationField ? 'grid w-full' : 'flex flex-row items-center'
          )}
        >
          <span>Startdatum {toReadableDate(item.startDate)}</span>
          {item.endDate && (
            <span>Slutdatum {toReadableDate(item.endDate)}</span>
          )}
        </div>
      ),
    }),
  };
