import { Icons } from '@/components/icons';
import {
  defaultBooleanFilter,
  defaultDateFilter,
  defaultEnumFilter,
  defaultNullFilter,
  defaultNumberFilter,
  defaultRelationFilter,
  defaultSelectFilter,
  defaultStringFilter,
  defaultTagFilter,
  OPERATORS,
  RELATION_FILTER,
  type Filter,
} from '@/components/table-filters/constants';
import {
  ApartmentConfig,
  ApartmentContractRevenueRowConfig,
  ApartmentRevenueRowConfig,
  ArticleConfig,
  BuildingConfig,
  OrganizationUserConfig,
  RealEstateConfig,
} from '@pigello/pigello-matrix';

const baseFilters: Filter[] = [
  {
    ...defaultDateFilter,
    title: 'Skapades',
    externalFieldName: 'created_at',
  },
];

export const baseMonitoringFilters: Filter[] = [
  {
    ...defaultSelectFilter,
    title: 'Typ',
    externalFieldName: 'mt_action_kind',
    choices: [
      { d: 'Skapades', v: 0 },
      { d: 'Uppdaterades', v: 1 },
      { d: 'Raderades', v: 2 },
    ],
  },
  {
    ...defaultStringFilter,
    title: 'Motivering',
    externalFieldName: 'mt_motivation',
  },
  {
    ...defaultDateFilter,
    title: 'Förfrågades',
    externalFieldName: 'mt_added_time',
  },
  {
    ...defaultRelationFilter,
    title: 'Förfrågades av användare',
    externalFieldName: 'mt_by_organization_user',
    relationModelName: OrganizationUserConfig.modelName,
    icon: Icons.user,
  },
];

export const baseTagFilter: Filter[] = [
  {
    ...defaultTagFilter,
    title: 'Taggar',
    externalFieldName: 'tags',
    relationModelName: 'tag',
    icon: Icons.tag,
  },
];

const SPACE_STATUS_FILTERS: Filter['enumChoices'] = [
  {
    filters: [{ operator: '__in', value: '10,11,12' }],
    title: 'Vakant',
    badgeVariant: 'destructive',
  },
  {
    filters: [{ operator: '__in', value: '0,1,2,3,4,5,6,7,8' }],
    title: 'Uthyrd',
    badgeVariant: 'green',
  },

  {
    filters: [{ operator: '__in', value: '1' }],
    title: 'Uthyrd (Tillsvidare)',
    badgeVariant: 'green',
  },
  {
    filters: [{ operator: '__in', value: '2' }],
    title: 'Uthyrd (Tidsbestämt)',
    badgeVariant: 'green',
  },
  {
    filters: [{ operator: '__in', value: '3' }],
    title: 'Uthyrd (Pågående uppsägning för villkorsändring)',
    badgeVariant: 'yellow',
  },
  {
    filters: [{ operator: '__in', value: '4' }],
    title: 'Uthyrd (Pågående uppsägning för avflytt)',
    badgeVariant: 'yellow',
  },
  {
    filters: [{ operator: '__in', value: '5' }],
    title: 'Uthyrd (Uppsagt för villkorsändring)',
    badgeVariant: 'yellow',
  },
  {
    filters: [{ operator: '__in', value: '6' }],
    title: 'Uthyrd (Uppsagt för avflytt)',
    badgeVariant: 'yellow',
  },
  {
    filters: [{ operator: '__in', value: '7,8' }],
    title: 'Uthyrd (Utgående)',
    badgeVariant: 'yellow',
  },
  {
    filters: [{ operator: '__in', value: '9' }],
    title: 'Kommande',
    badgeVariant: 'blue',
  },
  {
    filters: [{ operator: '__in', value: '10' }],
    title: 'Kommande (Ej signerat)',
    badgeVariant: 'purple',
  },
];

export const baseSpaceStatusFilters: Filter[] = [
  {
    ...defaultEnumFilter,
    title: ApartmentConfig.fields.status.verboseName,
    externalFieldName: ApartmentConfig.fields.status.externalFieldName,
    enumChoices: SPACE_STATUS_FILTERS,
  },
];

export const baseBuildingFilter: Filter[] = [
  {
    ...defaultRelationFilter,
    title: ApartmentConfig.fields.building.verboseName,
    externalFieldName: ApartmentConfig.fields.building.externalFieldName,
    relationModelName: BuildingConfig.modelName,
    icon: Icons.building,
  },
];
export const baseGlobalRealEstatesFilter: Filter[] = [
  {
    title: 'Fastigheter',
    externalFieldName: 'global_realestates',
    relationModelName: RealEstateConfig.modelName,
    kind: RELATION_FILTER,
    operators: [...OPERATORS.NOOP],
    defaultOperator: OPERATORS.NOOP[0],
    icon: Icons.realestate,
  },
];

export const baseAddressFilters: Filter[] = [
  {
    ...defaultStringFilter,
    title: `${ApartmentConfig.fields.address.verboseName} - Gatunamn`,
    externalFieldName: `${ApartmentConfig.fields.address.externalFieldName}__street`,
    icon: Icons.address,
  },
  {
    ...defaultStringFilter,
    title: `${ApartmentConfig.fields.address.verboseName} - Stad`,
    externalFieldName: `${ApartmentConfig.fields.address.externalFieldName}__city`,
    icon: Icons.address,
  },
  {
    ...defaultStringFilter,
    title: `${ApartmentConfig.fields.address.verboseName} - Gatunummer`,
    externalFieldName: `${ApartmentConfig.fields.address.externalFieldName}__street_number`,
    icon: Icons.address,
  },
  {
    ...defaultStringFilter,
    title: `${ApartmentConfig.fields.address.verboseName} - Land`,
    externalFieldName: `${ApartmentConfig.fields.address.externalFieldName}__country`,
    icon: Icons.address,
  },
];

export const baseSpaceDebitRowFilters: Filter[] = [
  {
    ...defaultNullFilter,
    title: 'Renovering på objekt',
    externalFieldName:
      ApartmentRevenueRowConfig.fields.spaceRenovationNotation
        .externalFieldName,
    icon: Icons.renovation,
  },
  {
    ...defaultNullFilter,
    title: 'Renovering på struktur',
    externalFieldName:
      ApartmentRevenueRowConfig.fields.structureRenovationNotation
        .externalFieldName,
    icon: Icons.renovation,
  },

  {
    ...defaultNullFilter,
    title: 'Fördelningsavgift',
    externalFieldName:
      ApartmentRevenueRowConfig.fields.automaticDistributionQuota
        .externalFieldName,
    icon: Icons.fee,
  },
];

export const baseContractDebitRowFilters: Filter[] = [
  {
    ...defaultNullFilter,
    title: 'Retroaktiv debitering',
    externalFieldName:
      ApartmentContractRevenueRowConfig.fields.fromRetroactiveProposal
        .externalFieldName,
    icon: Icons.fee,
  },
  {
    ...defaultNullFilter,
    title: 'Indexuppräkning',
    externalFieldName:
      ApartmentContractRevenueRowConfig.fields.indexationUsage
        .externalFieldName,
    icon: Icons.indexation,
  },
  {
    ...defaultNullFilter,
    title: 'Indexuppräknas',
    externalFieldName:
      ApartmentContractRevenueRowConfig.fields.appliedIndexationUsage
        .externalFieldName,
    icon: Icons.indexation,
  },
  {
    ...defaultNullFilter,
    title: 'Omsättningshyra',
    externalFieldName:
      ApartmentContractRevenueRowConfig.fields.customerEarningsSetting
        .externalFieldName,
    icon: Icons.fee,
  },
  {
    ...defaultBooleanFilter,
    title: 'Böter från sen rapport av omsättning',
    externalFieldName:
      ApartmentContractRevenueRowConfig.fields.isCustomerEarningsReportPenalty
        .externalFieldName,
  },
  {
    ...defaultNullFilter,
    title: 'Fördelningsavgift',
    externalFieldName:
      ApartmentContractRevenueRowConfig.fields.automaticDistributionQuota
        .externalFieldName,
    icon: Icons.fee,
  },
];

export const baseDebitRowFilters: Filter[] = [
  {
    ...defaultStringFilter,
    title: ApartmentRevenueRowConfig.fields.title.verboseName,
    externalFieldName: ApartmentRevenueRowConfig.fields.title.externalFieldName,
  },
  {
    ...defaultRelationFilter,
    title: ApartmentRevenueRowConfig.fields.article.verboseName,
    externalFieldName:
      ApartmentRevenueRowConfig.fields.article.externalFieldName,
    relationModelName: ArticleConfig.modelName,
    icon: Icons.article,
  },
  {
    ...defaultNumberFilter,
    title: ApartmentRevenueRowConfig.fields.unitPrice.verboseName,
    externalFieldName:
      ApartmentRevenueRowConfig.fields.unitPrice.externalFieldName,
    icon: Icons.fee,
  },
  {
    ...defaultNumberFilter,
    title: ApartmentRevenueRowConfig.fields.unitAmount.verboseName,
    externalFieldName:
      ApartmentRevenueRowConfig.fields.unitAmount.externalFieldName,
    icon: Icons.fee,
  },
  {
    ...defaultNumberFilter,
    title: ApartmentRevenueRowConfig.fields.startDate.verboseName,
    externalFieldName:
      ApartmentRevenueRowConfig.fields.startDate.externalFieldName,
    icon: Icons.date,
  },
  {
    ...defaultNumberFilter,
    title: ApartmentRevenueRowConfig.fields.endDate.verboseName,
    externalFieldName:
      ApartmentRevenueRowConfig.fields.endDate.externalFieldName,
    icon: Icons.date,
  },
  {
    ...defaultStringFilter,
    title: ApartmentRevenueRowConfig.fields.customId.verboseName,
    externalFieldName:
      ApartmentRevenueRowConfig.fields.customId.externalFieldName,
    icon: Icons.id,
  },
  {
    ...defaultBooleanFilter,
    title:
      ApartmentRevenueRowConfig.fields.periodsAdjustsTotalAmount.verboseName,
    externalFieldName:
      ApartmentRevenueRowConfig.fields.periodsAdjustsTotalAmount
        .externalFieldName,
    icon: Icons.date,
  },
  {
    ...defaultSelectFilter,
    title: ApartmentRevenueRowConfig.fields.excemptFromMonths.verboseName,
    externalFieldName:
      ApartmentRevenueRowConfig.fields.excemptFromMonths.externalFieldName,
    choices: ApartmentRevenueRowConfig.fields.excemptFromMonths.choices,
    icon: Icons.date,
  },
  ...baseFilters,
];

export default baseFilters;
