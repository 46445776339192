import commonAreaFilters from '@/app/dashboard/common-areas/filters';
import { cn } from '@/lib/cn';
import { toReadableAddress } from '@/lib/utils';
import { getRelationFieldNames } from '@/requests/instanceMapper';
import { CommonAreaConfig, type CommonArea } from '@pigello/pigello-matrix';
import Dot from './dot';
import type { RelationFieldProps } from './types';

export const CommonAreaItem: RelationFieldProps<CommonArea> = {
  nested: getRelationFieldNames(CommonAreaConfig),
  filters: commonAreaFilters,
  renderItem: (item, isRelationField) => ({
    title: item?.popularName ?? item.customId,
    badge: (
      <span className='text-xs'>
        {' '}
        {CommonAreaConfig.fields.category.choices?.find(
          (c) => c.v == item.category
        )?.d ?? 'Ingen kategori angiven'}
      </span>
    ),
    descriptionItems: (
      <div
        className={cn(
          'flex w-full items-center',
          isRelationField && 'justify-between'
        )}
      >
        <span className='font-medium'>
          {item.address
            ? toReadableAddress(item.address, true, false)
            : 'Ingen adress angiven'}
        </span>
        {!isRelationField && <Dot />}
        <span>{item.customId ?? 'Inget objekts-id angivet'}</span>
      </div>
    ),
  }),
};
