'use client';

import vacancyInvoiceFilters from '@/app/dashboard/invoicing/vacancy-invoices/filters';
import { Badge } from '@/components/ui/badge';
import { toReadableDate } from '@/lib/dates';
import { getRelationFieldNames } from '@/requests/instanceMapper';
import {
  ApartmentVacancyNotationConfig,
  IndustrialPremisesVacancyNotationConfig,
  OutdoorSectionVacancyNotationConfig,
  ParkingSpotVacancyNotationConfig,
  VacancyInvoiceConfig,
  type ContentType,
  type VacancyInvoice,
} from '@pigello/pigello-matrix';
import type { RelationFieldProps } from './types';

const getConfigFromContentType = (contentType: ContentType) => {
  switch (contentType) {
    case 'objects.apartmentvacancynotation':
      return ApartmentVacancyNotationConfig;
    case 'objects.industrialpremisesvacancynotation':
      return IndustrialPremisesVacancyNotationConfig;
    case 'objects.outdoorsectionvacancynotation':
      return OutdoorSectionVacancyNotationConfig;
    case 'objects.parkingspotvacancynotation':
      return ParkingSpotVacancyNotationConfig;
    default:
      return null;
  }
};

export const VacancyInvoiceItem: RelationFieldProps<VacancyInvoice> = {
  filters: vacancyInvoiceFilters,
  nested: getRelationFieldNames(VacancyInvoiceConfig),
  renderItem: (item) => ({
    title:
      getConfigFromContentType(
        item.originatesFromContentType?.id as ContentType
      )?.verboseName ?? 'Okänt',
    badge: item.annulled ? (
      <Badge variant={'destructive'}>Makulerad</Badge>
    ) : (
      <Badge variant={item.attestedDate ? 'green' : 'yellow'}>
        {item.attestedDate ? 'Attesterad' : 'Ej attesterad'}
      </Badge>
    ),
    descriptionItems: (
      <div className='grid w-full'>
        <span>Period från {toReadableDate(item.periodStart)}</span>
        {<span>Period till {toReadableDate(item.periodEnd)}</span>}
      </div>
    ),
  }),
};
