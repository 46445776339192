'use client';
import outdoorAreaFilters from '@/app/dashboard/structural/outdoorareas/filters';
import { cn } from '@/lib/cn';
import { toReadableAddress } from '@/lib/utils';
import { getRelationFieldNames } from '@/requests/instanceMapper';
import { OutdoorAreaConfig, type OutdoorArea } from '@pigello/pigello-matrix';
import Dot from './dot';
import type { RelationFieldProps } from './types';
export const OutdoorAreaItem: RelationFieldProps<OutdoorArea> = {
  nested: getRelationFieldNames(OutdoorAreaConfig),
  filters: outdoorAreaFilters,
  renderItem: (item, isRelationField) => ({
    title: item?.name,
    descriptionItems: (
      <div
        className={cn(
          'flex w-full items-center',
          isRelationField && 'justify-between'
        )}
      >
        <span className='font-medium'>
          {item.address
            ? toReadableAddress(item.address, true, false)
            : 'Ingen adress angiven'}
        </span>
        {!isRelationField && <Dot />}
        <span>{item?.customId ?? 'Inget objekts-id angivet'}</span>
      </div>
    ),
  }),
};
