import {
  defaultRelationFilter,
  type Filter,
} from '@/components/table-filters/constants';
import {
  OutdoorSectionConfig,
  OutdoorSectionContractConfig,
} from '@pigello/pigello-matrix';
import baseFilters, {
  baseGlobalRealEstatesFilter,
  baseTagFilter,
} from '../../filters';
import baseContractFilters from '../components/base-contract-filters';
import { Icons } from '@/components/icons';

const outdoorSectionContractFilters: Filter[] = [
  {
    ...defaultRelationFilter,
    title: OutdoorSectionContractConfig.fields.space.verboseName,
    relationModelName: OutdoorSectionConfig.modelName,
    externalFieldName:
      OutdoorSectionContractConfig.fields.space.externalFieldName,
    icon: Icons.outdoorsectioncontract,
  },
  ...baseGlobalRealEstatesFilter,
  ...baseContractFilters,
  ...baseTagFilter,
  ...baseFilters,
];

export default outdoorSectionContractFilters;
