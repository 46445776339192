'use client';

import { Badge } from '@/components/ui/badge';
import { cn } from '@/lib/cn';
import { getRelationFieldNames } from '@/requests/instanceMapper';
import {
  BrfApartmentInvoiceConfigurationConfig,
  type BrfApartmentInvoiceConfiguration,
} from '@pigello/pigello-matrix';
import { DateTime } from 'luxon';
import Dot from './dot';
import type { RelationFieldProps } from './types';

function BrfStatusBadge({ item }: { item: BrfApartmentInvoiceConfiguration }) {
  const now = DateTime.now();
  const isStarted = DateTime.fromISO(item.periodFrom) < now;
  const isEnded = item.periodTo && DateTime.fromISO(item.periodTo) < now;
  const status =
    isStarted && isEnded ? 'earlier' : isStarted ? 'active' : 'upcoming';

  return (
    <div className='flex items-center space-x-2'>
      <Badge
        variant={
          status === 'active' ? 'green' : status === 'earlier' ? 'gray' : 'blue'
        }
      >
        {status === 'active'
          ? 'Aktivt'
          : status === 'earlier'
            ? 'Tidigare'
            : 'Kommande'}
      </Badge>
    </div>
  );
}

function getPeriodText(item: BrfApartmentInvoiceConfiguration) {
  if (item.periodFrom && !item.periodTo) {
    return `${item.periodFrom} - tillsvidare`;
  }
  return `${item.periodFrom} - ${item.periodTo}`;
}

export const BrfApartmentInvoicingConfigurationItem: RelationFieldProps<BrfApartmentInvoiceConfiguration> =
  {
    nested: getRelationFieldNames(BrfApartmentInvoiceConfigurationConfig),
    filters: [],
    renderItem: (item, isRelationField) => ({
      title: item.configurationId,
      badge: <BrfStatusBadge item={item} />,
      descriptionItems: (
        <div
          className={cn(
            'flex w-full items-center',
            isRelationField && 'justify-between'
          )}
        >
          <div className='grid gap-1'>
            <div className='font-medium'>
              {item.tenants?.length === 1
                ? item.tenants?.[0]?.communicationName
                : item.tenants?.length > 1
                  ? `${item.tenants?.length} hyresgäster kopplade`
                  : 'Ingen hyresgäst kopplade'}
            </div>
            <span>{getPeriodText(item)}</span>
          </div>
          {!isRelationField && <Dot />}
          <span> {item.customId ?? 'Inget objekts-id angivet'}</span>
        </div>
      ),
    }),
  };
