'use client';
import addressFilters from '@/app/dashboard/structural/addresses/filters';
import { cn } from '@/lib/cn';
import { toReadableAddress } from '@/lib/utils';
import { getRelationFieldNames } from '@/requests/instanceMapper';
import { AddressConfig, type Address } from '@pigello/pigello-matrix';
import Dot from './dot';
import type { RelationFieldProps } from './types';

export const AddressItem: RelationFieldProps<Address> = {
  nested: getRelationFieldNames(AddressConfig),
  filters: addressFilters,
  renderItem: (item, isRelationField) => ({
    title: String(toReadableAddress(item, true, false)),
    descriptionItems: (
      <div
        className={cn(
          isRelationField ? 'grid w-full' : 'flex flex-row items-center'
        )}
      >
        {!isRelationField && <Dot />}
        <span> {item.customId ?? 'Inget objekts-id angivet'}</span>
      </div>
    ),
  }),
};
