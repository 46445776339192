import { Icons } from '@/components/icons';
import {
  DATE_FILTER,
  type Filter,
} from '@/components/table-filters/constants';

const baseRevenueIncreaseFilters: Filter[] = [
  {
    title: 'Senaste avgiftsändringen',
    externalFieldName: 'revenue_increase_created_at',
    operators: ['__lte'],
    defaultOperator: '__lte',
    kind: DATE_FILTER,
    icon: Icons.date,
  },
  {
    title: 'Ej avgiftsändrad sedan',
    externalFieldName: 'revenue_increase_not_created',
    operators: ['__gte'],
    defaultOperator: '__gte',
    kind: DATE_FILTER,
    icon: Icons.date,
  },
];

export default baseRevenueIncreaseFilters;
