// inspired by https://github.com/onderonur/react-infinite-scroll-hook
import { useEffect } from 'react';
import type {
  IntersectionObserverHookArgs,
  IntersectionObserverHookRefCallback as UseInfiniteScrollHookRefCallback,
  IntersectionObserverHookRootRefCallback as UseInfiniteScrollHookRootRefCallback,
} from 'react-intersection-observer-hook';
import { useTrackVisibility } from 'react-intersection-observer-hook';

export type UseInfiniteScrollHookResult = [
  UseInfiniteScrollHookRefCallback,
  { rootRef: UseInfiniteScrollHookRootRefCallback },
];

export type UseInfiniteScrollHookArgs = Pick<
  IntersectionObserverHookArgs,
  'rootMargin'
> & {
  loading: boolean;
  hasNextPage?: boolean;
  onLoadMore: VoidFunction;
  disabled?: boolean;
  delayInMs?: number;
};

export default function useInfiniteScroll({
  loading,
  hasNextPage,
  onLoadMore,
  rootMargin,
  disabled,
  delayInMs = 100,
}: UseInfiniteScrollHookArgs): UseInfiniteScrollHookResult {
  const [ref, { rootRef, isVisible }] = useTrackVisibility({
    rootMargin,
  });

  const shouldLoadMore = !disabled && !loading && isVisible && hasNextPage;
  useEffect(() => {
    if (shouldLoadMore) {
      const timer = setTimeout(() => {
        onLoadMore();
      }, delayInMs);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [onLoadMore, shouldLoadMore, delayInMs]);

  return [ref, { rootRef }];
}
