import type { FieldValue } from '@pigello/pigello-matrix';
import { DateTime, Settings } from 'luxon';

Settings.defaultLocale = 'sv';

export const toReadableDate = (date?: FieldValue<string>) => {
  if (!date) return '-';

  return DateTime.fromISO(date).toFormat('yyyy-MM-dd');
};

export const toReadableDateTime = (date: FieldValue<string>) => {
  if (!date) return '-';
  return DateTime.fromISO(date).toFormat('yyyy-MM-dd HH:mm');
};

export function monthIndexToString(monthIndex: number) {
  switch (monthIndex) {
    case 1:
      return 'Januari';
    case 2:
      return 'Februari';
    case 3:
      return 'Mars';
    case 4:
      return 'April';
    case 5:
      return 'Maj';
    case 6:
      return 'Juni';
    case 7:
      return 'Juli';
    case 8:
      return 'Augusti';
    case 9:
      return 'September';
    case 10:
      return 'Oktober';
    case 11:
      return 'November';
    case 12:
      return 'December';
    default:
      return '';
  }
}
