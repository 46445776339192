import externalClientFilters from '@/app/dashboard/external-clients/filters';
import { cn } from '@/lib/cn';
import { getRelationFieldNames } from '@/requests/instanceMapper';
import { DebtInvoiceConfig, type DebtInvoice } from '@pigello/pigello-matrix';
import type { RelationFieldProps } from './types';
export const DebtInvoiceItem: RelationFieldProps<DebtInvoice> = {
  nested: getRelationFieldNames(DebtInvoiceConfig),
  filters: externalClientFilters,
  renderItem: (item, isRelationField) => ({
    title: `${item?.debtDate} - ${item.kind === 0 ? 'Påminnelse' : 'Krav'}`,
    descriptionItems: (
      <div
        className={cn(
          'flex w-full items-center',
          isRelationField && 'justify-between'
        )}
      >
        <span className='font-medium'>
          {DebtInvoiceConfig.fields.debtStage.choices?.find(
            (c) => c.v === item?.debtStage
          )?.d ?? 'Påminnelse'}
        </span>
      </div>
    ),
  }),
};
