import outdoorSectionFilters from '@/app/dashboard/outdoor-sections/filters';
import { StatusBadge } from '@/components/ui/statusBadge';
import { cn } from '@/lib/cn';
import { toReadableAddress, toSqm } from '@/lib/utils';
import { getRelationFieldNames } from '@/requests/instanceMapper';
import {
  OutdoorSectionConfig,
  type OutdoorSection,
} from '@pigello/pigello-matrix';
import Dot from './dot';
import type { RelationFieldProps } from './types';

export const OutdoorSectionItem: RelationFieldProps<OutdoorSection> = {
  nested: getRelationFieldNames(OutdoorSectionConfig),
  filters: outdoorSectionFilters,
  renderItem: (item, isRelationField) => ({
    title: item.customId ?? item?.popularName ?? 'Ingen namn angivet',
    badge: <StatusBadge type='object' status={item.status} />,
    descriptionItems: (
      <div
        className={cn(
          'flex w-full items-center',
          isRelationField && 'justify-between'
        )}
      >
        <span className='text-xs'>
          {' '}
          {OutdoorSectionConfig.fields.category.choices?.find(
            (c) => c.v == item.category
          )?.d ?? 'Ingen kategori angiven'}
        </span>
        {!isRelationField && <Dot />}
        <span className='font-medium'>
          {item.address
            ? toReadableAddress(item.address, true, false)
            : 'Ingen adress angiven'}
        </span>
        {!isRelationField && <Dot />}
        {item.usableArea ? toSqm(item.usableArea) : 'Ingen area angiven'}
      </div>
    ),
  }),
};
