import {
  defaultStringFilter,
  type Filter,
} from '@/components/table-filters/constants';
import { CompanyConfig } from '@pigello/pigello-matrix';
import baseFilters, { baseTagFilter } from '../filters';
import { Icons } from '@/components/icons';

const companyFilters: Filter[] = [
  {
    ...defaultStringFilter,
    title: CompanyConfig.fields.name.verboseName,
    externalFieldName: CompanyConfig.fields.name.externalFieldName,
  },
  {
    ...defaultStringFilter,
    title: CompanyConfig.fields.orgNo.verboseName,
    externalFieldName: CompanyConfig.fields.orgNo.externalFieldName,
    icon: Icons.id,
  },
  {
    ...defaultStringFilter,
    title: CompanyConfig.fields.customId.verboseName,
    externalFieldName: CompanyConfig.fields.customId.externalFieldName,
    icon: Icons.id,
  },
  {
    ...defaultStringFilter,
    title: CompanyConfig.fields.sniNumber.verboseName,
    externalFieldName: CompanyConfig.fields.sniNumber.externalFieldName,
    icon: Icons.number,
  },

  {
    ...defaultStringFilter,
    title: `${CompanyConfig.fields.postalAddress.verboseName} - Gatunamn`,
    externalFieldName: `${CompanyConfig.fields.postalAddress.externalFieldName}__street`,
    icon: Icons.address,
  },
  {
    ...defaultStringFilter,
    title: `${CompanyConfig.fields.postalAddress.verboseName} - Stad`,
    externalFieldName: `${CompanyConfig.fields.postalAddress.externalFieldName}__city`,
    icon: Icons.address,
  },
  {
    ...defaultStringFilter,
    title: `${CompanyConfig.fields.postalAddress.verboseName} - Gatunummer`,
    externalFieldName: `${CompanyConfig.fields.postalAddress.externalFieldName}__street_number`,
    icon: Icons.address,
  },
  {
    ...defaultStringFilter,
    title: `${CompanyConfig.fields.postalAddress.verboseName} - Land`,
    externalFieldName: `${CompanyConfig.fields.postalAddress.externalFieldName}__country`,
    icon: Icons.address,
  },
  {
    ...defaultStringFilter,
    title: `${CompanyConfig.fields.visitingAddress.verboseName} - Gatunamn`,
    externalFieldName: `${CompanyConfig.fields.visitingAddress.externalFieldName}__street`,
    icon: Icons.address,
  },
  {
    ...defaultStringFilter,
    title: `${CompanyConfig.fields.visitingAddress.verboseName} - Stad`,
    externalFieldName: `${CompanyConfig.fields.visitingAddress.externalFieldName}__city`,
    icon: Icons.address,
  },
  {
    ...defaultStringFilter,
    title: `${CompanyConfig.fields.visitingAddress.verboseName} - Gatunummer`,
    externalFieldName: `${CompanyConfig.fields.visitingAddress.externalFieldName}__street_number`,
    icon: Icons.address,
  },
  {
    ...defaultStringFilter,
    title: `${CompanyConfig.fields.visitingAddress.verboseName} - Land`,
    externalFieldName: `${CompanyConfig.fields.visitingAddress.externalFieldName}__country`,
    icon: Icons.address,
  },
  {
    ...defaultStringFilter,
    title: `${CompanyConfig.fields.registeredAddress.verboseName} - Gatunamn`,
    externalFieldName: `${CompanyConfig.fields.registeredAddress.externalFieldName}__street`,
    icon: Icons.address,
  },
  {
    ...defaultStringFilter,
    title: `${CompanyConfig.fields.registeredAddress.verboseName} - Stad`,
    externalFieldName: `${CompanyConfig.fields.registeredAddress.externalFieldName}__city`,
    icon: Icons.address,
  },
  {
    ...defaultStringFilter,
    title: `${CompanyConfig.fields.registeredAddress.verboseName} - Gatunummer`,
    externalFieldName: `${CompanyConfig.fields.registeredAddress.externalFieldName}__street_number`,
    icon: Icons.address,
  },
  {
    ...defaultStringFilter,
    title: `${CompanyConfig.fields.registeredAddress.verboseName} - Land`,
    externalFieldName: `${CompanyConfig.fields.registeredAddress.externalFieldName}__country`,
    icon: Icons.address,
  },

  ...baseTagFilter,
  ...baseFilters,
];

export default companyFilters;
