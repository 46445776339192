import realEstateFilters from '@/app/dashboard/structural/realestates/filters';
import { cn } from '@/lib/cn';
import { toReadableAddress } from '@/lib/utils';
import { type RealEstate } from '@pigello/pigello-matrix';
import Dot from './dot';
import type { RelationFieldProps } from './types';

export const RealestateItem: RelationFieldProps<RealEstate> = {
  filters: realEstateFilters,
  renderItem: (item, isRelationField) => ({
    title: `${item?.name} ${item?.popularName ? `(${item?.popularName})` : ''}`,
    descriptionItems: (
      <div
        className={cn(
          'flex w-full items-center',
          isRelationField && 'justify-between'
        )}
      >
        <span className='font-medium'>
          {item.mainAddress
            ? toReadableAddress(item.mainAddress, true, false)
            : 'Ingen adress angiven'}
        </span>
        {!isRelationField && <Dot />}
        <span>{item?.customId ?? 'Inget objekts-id angivet'}</span>
      </div>
    ),
  }),
};
