import {
  defaultBooleanFilter,
  defaultStringFilter,
  type Filter,
} from '@/components/table-filters/constants';
import { ArticleCategoryConfig } from '@pigello/pigello-matrix';

const articleCategoryFilters: Filter[] = [
  {
    ...defaultStringFilter,
    title: ArticleCategoryConfig.fields.name.verboseName,
    externalFieldName: ArticleCategoryConfig.fields.name.externalFieldName,
  },
  {
    ...defaultBooleanFilter,
    title: ArticleCategoryConfig.fields.archived.verboseName,
    externalFieldName: ArticleCategoryConfig.fields.archived.externalFieldName,
  },
  {
    ...defaultBooleanFilter,
    title: ArticleCategoryConfig.fields.considerBaseRent.verboseName,
    externalFieldName:
      ArticleCategoryConfig.fields.considerBaseRent.externalFieldName,
  },
];

export { articleCategoryFilters };
