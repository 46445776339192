'use client';

import otherContractFilters from '@/app/dashboard/contracts/other-contracts/filters';
import { StatusBadge } from '@/components/ui/statusBadge';
import { cn } from '@/lib/cn';
import { getRelationFieldNames } from '@/requests/instanceMapper';
import {
  OtherContractConfig,
  type OtherContract,
} from '@pigello/pigello-matrix';
import Dot from './dot';
import type { RelationFieldProps } from './types';

export const OtherContractItem: RelationFieldProps<OtherContract> = {
  nested: getRelationFieldNames(OtherContractConfig),
  filters: otherContractFilters,
  renderItem: (item, isRelationField) => ({
    title: item?.idNumber,
    badge:
      item.status != null ? (
        <StatusBadge
          className='font-medium'
          status={item.status}
          type='contract'
        />
      ) : undefined,
    descriptionItems: (
      <div
        className={cn(
          'flex w-full items-center',
          isRelationField && 'justify-between'
        )}
      >
        <div className='font-medium'>
          {item.tenantCounterpart?.communicationName ??
            'Ingen hyresgäst kopplad'}
        </div>
        {!isRelationField && <Dot />}
        <span> {item.customId ?? 'Inget objekts-id angivet'}</span>
      </div>
    ),
  }),
};
