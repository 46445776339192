import type { StatusType } from '../data-table/constants';
import { StatusMap } from '../data-table/constants';
import { Badge } from './badge';

type Status = keyof (typeof StatusMap)[StatusType];

export function StatusBadge({
  status,
  className,
  type = 'object',
}: {
  type?: StatusType;
  status?: Status;
  className?: string;
}) {
  if (status == null) {
    return null;
  }
  return (
    <Badge className={className} variant={StatusMap[type][status]?.color}>
      {StatusMap[type][status]?.text}
    </Badge>
  );
}
