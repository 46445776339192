import {
  defaultRelationFilter,
  defaultStringFilter,
  type Filter,
} from '@/components/table-filters/constants';
import { CommunityConfig, RegionConfig } from '@pigello/pigello-matrix';
import baseFilters, { baseTagFilter } from '../../filters';
import { Icons } from '@/components/icons';

const communityFilters: Filter[] = [
  {
    ...defaultRelationFilter,
    title: CommunityConfig.fields.region.verboseName,
    externalFieldName: CommunityConfig.fields.region.externalFieldName,
    relationModelName: RegionConfig.modelName,
    icon: Icons.region,
  },
  {
    ...defaultStringFilter,
    title: CommunityConfig.fields.name.verboseName,
    externalFieldName: CommunityConfig.fields.name.externalFieldName,
  },
  {
    ...defaultStringFilter,
    title: CommunityConfig.fields.customId.verboseName,
    externalFieldName: CommunityConfig.fields.customId.externalFieldName,
  },
  {
    ...defaultStringFilter,
    title: `${CommunityConfig.fields.mainAddress.verboseName} - Gatunamn`,
    externalFieldName: `${CommunityConfig.fields.mainAddress.externalFieldName}__street`,
  },
  {
    ...defaultStringFilter,
    title: `${CommunityConfig.fields.mainAddress.verboseName} - Stad`,
    externalFieldName: `${CommunityConfig.fields.mainAddress.externalFieldName}__city`,
  },
  {
    ...defaultStringFilter,
    title: `${CommunityConfig.fields.mainAddress.verboseName} - Gatunummer`,
    externalFieldName: `${CommunityConfig.fields.mainAddress.externalFieldName}__street_number`,
  },
  {
    ...defaultStringFilter,
    title: `${CommunityConfig.fields.mainAddress.verboseName} - Land`,
    externalFieldName: `${CommunityConfig.fields.mainAddress.externalFieldName}__country`,
  },
  ...baseTagFilter,
  ...baseFilters,
];

export default communityFilters;
