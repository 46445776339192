import communityFilters from '@/app/dashboard/structural/communities/filters';
import { getRelationFieldNames } from '@/requests/instanceMapper';
import { CommunityConfig, type Community } from '@pigello/pigello-matrix';
import type { RelationFieldProps } from './types';

export const CommunityItem: RelationFieldProps<Community> = {
  nested: getRelationFieldNames(CommunityConfig),
  filters: communityFilters,
  renderItem: (item) => ({
    title: item?.name,
    descriptionItems: (
      <span className='font-medium'>
        {item?.customId ?? 'Inget objekts-id angivet'}
      </span>
    ),
  }),
};
