import {
  defaultRelationFilter,
  defaultSelectFilter,
  defaultStringFilter,
  type Filter,
} from '@/components/table-filters/constants';
import {
  CommunityConfig,
  CompanyConfig,
  RealEstateConfig,
} from '@pigello/pigello-matrix';
import baseFilters, { baseTagFilter } from '../../filters';

const realEstateFilters: Filter[] = [
  {
    ...defaultStringFilter,
    title: RealEstateConfig.fields.name.verboseName,
    externalFieldName: RealEstateConfig.fields.name.externalFieldName,
  },
  {
    ...defaultStringFilter,
    title: RealEstateConfig.fields.customId.verboseName,
    externalFieldName: RealEstateConfig.fields.customId.externalFieldName,
  },
  {
    ...defaultRelationFilter,
    title: RealEstateConfig.fields.community.verboseName,
    externalFieldName: RealEstateConfig.fields.community.externalFieldName,
    relationModelName: CommunityConfig.modelName,
  },
  {
    ...defaultRelationFilter,
    title: RealEstateConfig.fields.ownedBy.verboseName,
    externalFieldName: RealEstateConfig.fields.ownedBy.externalFieldName,
    relationModelName: CompanyConfig.modelName,
  },
  {
    ...defaultSelectFilter,
    title: RealEstateConfig.fields.typeCode.verboseName,
    externalFieldName: RealEstateConfig.fields.typeCode.externalFieldName,
    choices: RealEstateConfig.fields.typeCode.choices,
  },
  {
    ...defaultStringFilter,
    title: `${RealEstateConfig.fields.mainAddress.verboseName} - Gatunamn`,
    externalFieldName: `${RealEstateConfig.fields.mainAddress.externalFieldName}__street`,
  },
  {
    ...defaultStringFilter,
    title: `${RealEstateConfig.fields.mainAddress.verboseName} - Stad`,
    externalFieldName: `${RealEstateConfig.fields.mainAddress.externalFieldName}__city`,
  },
  {
    ...defaultStringFilter,
    title: `${RealEstateConfig.fields.mainAddress.verboseName} - Gatunummer`,
    externalFieldName: `${RealEstateConfig.fields.mainAddress.externalFieldName}__street_number`,
  },
  {
    ...defaultStringFilter,
    title: `${RealEstateConfig.fields.mainAddress.verboseName} - Land`,
    externalFieldName: `${RealEstateConfig.fields.mainAddress.externalFieldName}__country`,
  },

  ...baseTagFilter,
  ...baseFilters,
];
export default realEstateFilters;
