import {
  TaxationRecordConfig,
  type TaxationRecord,
} from '@pigello/pigello-matrix';
import Dot from './dot';
import type { RelationFieldProps } from './types';

export const TaxationRecordItem: RelationFieldProps<TaxationRecord> = {
  filters: [],
  renderItem: (item) => ({
    title: `Taxeringsenhet nummer ${item?.taxationIdentifier}`,
    descriptionItems: (
      <div className='flex w-full items-center'>
        <>
          <span>
            {TaxationRecordConfig.fields.forYear.verboseName} {item.forYear}
          </span>
          <Dot />
          <span>
            {TaxationRecordConfig.fields.taxationRule.choices?.find(
              (c) => c.v == item.taxationRule
            )?.d ?? '-'}
          </span>
          <Dot />
          <span>
            {TaxationRecordConfig.fields.typeCode.verboseName} {item.typeCode}
          </span>
        </>
      </div>
    ),
  }),
};
