import {
  defaultBooleanFilter,
  defaultDateFilter,
  defaultRelationFilter,
  defaultStringFilter,
  type Filter,
} from '@/components/table-filters/constants';

import {
  IndustrialPremisesConfig,
  IndustrialPremisesVacancyNotationConfig,
} from '@pigello/pigello-matrix';
import baseFilters, {
  baseGlobalRealEstatesFilter,
  baseTagFilter,
} from '../../filters';
import { Icons } from '@/components/icons';

const industrialPremisesVacancyContractFilter: Filter[] = [
  {
    ...defaultStringFilter,
    title: 'Avtals-ID',
    externalFieldName: 'custom_id',
  },
  {
    ...defaultRelationFilter,
    title: IndustrialPremisesVacancyNotationConfig.fields.space.verboseName,
    relationModelName: IndustrialPremisesConfig.modelName,
    externalFieldName:
      IndustrialPremisesVacancyNotationConfig.fields.space.externalFieldName,
    icon: Icons.industrialpremises,
  },
  {
    ...defaultBooleanFilter,
    title: IndustrialPremisesVacancyNotationConfig.fields.automatic.verboseName,
    externalFieldName: 'automatic',
  },
  {
    ...defaultBooleanFilter,
    title: IndustrialPremisesVacancyNotationConfig.fields.annulled.verboseName,
    externalFieldName: 'annulled',
  },
  {
    ...defaultDateFilter,
    title: IndustrialPremisesVacancyNotationConfig.fields.startDate.verboseName,
    externalFieldName: 'start_date',
  },
  {
    ...defaultDateFilter,
    title: IndustrialPremisesVacancyNotationConfig.fields.endDate.verboseName,
    externalFieldName: 'end_date',
  },
  ...baseGlobalRealEstatesFilter,
  ...baseTagFilter,
  ...baseFilters,
];

export default industrialPremisesVacancyContractFilter;
