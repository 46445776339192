import companyFilters from '@/app/dashboard/companies/filters';
import { cn } from '@/lib/cn';
import { getRelationFieldNames } from '@/requests/instanceMapper';
import { CompanyConfig, type Company } from '@pigello/pigello-matrix';
import Dot from './dot';
import type { RelationFieldProps } from './types';
export const CompanyItem: RelationFieldProps<Company> = {
  nested: getRelationFieldNames(CompanyConfig),
  filters: companyFilters,
  renderItem: (item, isRelationField) => ({
    title: item?.name,
    descriptionItems: (
      <div
        className={cn(
          'flex w-full items-center',
          isRelationField && 'justify-between'
        )}
      >
        <span className='font-medium'>
          {item.orgNo ?? 'Inget organisationsnummer angivet'}
        </span>
        {!isRelationField && <Dot />}
        <span>{item?.customId ?? 'Inget objekts-id angivet'}</span>
      </div>
    ),
  }),
};
